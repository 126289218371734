<template>
  <div class="trinoray-container">
    <div class="trinoray-trinoray">
      <div class="trinoray-footer">
        <div class="trinoray-frame59">
          <div class="trinoray-frame58">
            <div class="trinoray-frame50">
              <div class="trinoray-frame4">
                <div class="trinoray-frame70">
                  <img src="/external/vector1054-n6f.svg" alt="Vector1054" class="trinoray-vector1" />
                  <img src="/external/image202405281832021055-akay-200h.png" alt="Image202405281832021055"
                    class="trinoray-image202405281832021" />
                </div>
              </div>
              <span class="trinoray-text">
                <span>
                  This footer provides essential navigation links, customer
                  support information, social media icons for staying connected, a
                  newsletter subscription form,
                  <span v-html="rawcr12"></span>
                </span>
              </span>
            </div>
            <div class="trinoray-frame57">
              <div class="trinoray-frame52">
                <span class="trinoray-text002"><span>Quick Links</span></span>
                <div class="trinoray-frame51">
                  <span class="trinoray-text004"><span>How it works</span></span>
                  <span class="trinoray-text006"><span>About Us</span></span>
                  <span class="trinoray-text008"><span>Services</span></span>
                  <span class="trinoray-text010"><span>Solutions</span></span>
                  <span class="trinoray-text012"><span>Resources</span></span>
                </div>
              </div>
              <div class="trinoray-frame53">
                <span class="trinoray-text014">
                  <span>Customer Support</span>
                </span>
                <div class="trinoray-frame511">
                  <span class="trinoray-text016">
                    <span>Email: support@trinoray.com</span>
                  </span>
                  <span class="trinoray-text018">
                    <span>Phone: 1-800-123-4567</span>
                  </span>
                </div>
              </div>
              <div class="trinoray-frame56">
                <div class="trinoray-frame54">
                  <span class="trinoray-text020">
                    <span>Stay Connected</span>
                  </span>
                </div>
                <div class="trinoray-frame55">
                  <div class="trinoray-frame">
                    <div class="trinoray-group">
                      <img src="/external/vectori075-3okk.svg" alt="VectorI075" class="trinoray-vector" />
                    </div>
                  </div>
                  <div class="trinoray-insta">
                    <div class="trinoray-group01">
                      <img src="/external/vectori076-hrh.svg" alt="VectorI076" class="trinoray-vector01" />
                      <img src="/external/vectori076-k4ac.svg" alt="VectorI076" class="trinoray-vector02" />
                    </div>
                  </div>
                  <div class="trinoray-twitter">
                    <div class="trinoray-group02">
                      <img src="/external/vectori077-03gg.svg" alt="VectorI077" class="trinoray-vector03" />
                      <img src="/external/vectori077-560c.svg" alt="VectorI077" class="trinoray-vector04" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="trinoray-text022">
            <span>© 2024 trinoray. All Rights Reserved.</span>
          </span>
        </div>
      </div>
      <img src="/external/ellipse4079-3cv9-1000w.png" alt="Ellipse4079" class="trinoray-ellipse4" />
      <img src="/external/ellipse6080-dqtq-1000w.png" alt="Ellipse6080" class="trinoray-ellipse6" />
      <img src="/external/rectangle5081-btz-1200h.png" alt="Rectangle5081" class="trinoray-rectangle5" />
      <div class="trinoray-n-avbar">
        <div class="trinoray-frame3">
          <div class="trinoray-frame701">
            <img src="/external/vector1085-lg4a.svg" alt="Vector1085" />
            <img src="/external/image202405281832021086-ybpj-200h.png" alt="Image202405281832021086"
              class="trinoray-image2024052818320211" />
          </div>
        </div>
        <div class="trinoray-frame2">
          <span class="trinoray-text024"><span>About Us</span></span>
          <img src="/external/line5089-2bhi.svg" alt="Line5089" class="trinoray-line5" />
          <div class="trinoray-frame61">
            <span class="trinoray-text026"><span>Solution</span></span>
          </div>
          <img src="/external/line2092-ztnk.svg" alt="Line2092" class="trinoray-line2" />
          <span class="trinoray-text028"><span>Resources</span></span>
          <img src="/external/line4094-zmv.svg" alt="Line4094" class="trinoray-line4" />
          <span class="trinoray-text030"><span>Partnership</span></span>
        </div>
        <div class="trinoray-frame1">
          <span class="trinoray-text032"><span>Contact Us</span></span>
        </div>
      </div>
      <img src="/external/vector098-8k3.svg" alt="Vector098" class="trinoray-vector05" />
      <img src="/external/vector099-ip3a.svg" alt="Vector099" class="trinoray-vector06" />
      <img src="/external/vector0100-w0zb.svg" alt="Vector0100" class="trinoray-vector07" />
      <div class="trinoray-group3">
        <img src="/external/vector0102-r6c8.svg" alt="Vector0102" class="trinoray-vector08" />
        <img src="/external/vector0103-onas.svg" alt="Vector0103" class="trinoray-vector09" />
        <img src="/external/vector0104-l0r.svg" alt="Vector0104" class="trinoray-vector10" />
        <img src="/external/vector0105-b59.svg" alt="Vector0105" class="trinoray-vector11" />
      </div>
      <div class="trinoray-herosection">
        <div class="trinoray-frame7">
          <div class="trinoray-frame6">
            <div class="trinoray-frame5">
              <span class="trinoray-text034">
                <span class="trinoray-text035">
                  Introducing tailored solutions for humanitarian
                </span>
                <span class="trinoray-text036">
                  <span v-html="rawib3t"></span>
                </span>
                <span>
                  response teams working in conflict and natural disaster zones
                </span>
              </span>
            </div>
            <span class="trinoray-text038">
              <span>
                Developed by humanitarian field experts, for humanitarian teams
              </span>
            </span>
          </div>
          <div class="trinoray-frame41">
            <span class="trinoray-text040"><span>Read More</span></span>
            <div class="trinoray-forword">
              <div class="trinoray-group03">
                <img src="/external/vectori011-9lb2.svg" alt="VectorI011" class="trinoray-vector12" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-group2">
          <img src="/external/image205shotsso10116-mufj-800h.png" alt="IMAGE205shotsso10116"
            class="trinoray-image205shotsso1" />
        </div>
      </div>
      <div class="trinoray-ourcompanyvalues">
        <div class="trinoray-frame8">
          <div class="trinoray-frame18">
            <img src="/external/line60120-tauwd.svg" alt="Line60120" class="trinoray-line6" />
            <span class="trinoray-text042"><span>Our Ethos</span></span>
          </div>
          <span class="trinoray-text044">
            <span>
              Our values represent the fundamental beliefs and principles that
              guide our behaviors decisions , and actions. They are the foundation
              which Trinoray is built and is integral to shaping ou identity,
              vision, and objectives
            </span>
          </span>
        </div>
        <div>
          <div>
            <div>
              <div>
                <span class="trinoray-text046"><span>iNTEGRITY</span></span>
                <span class="trinoray-text048">
                  <span>
                    We adhere to moral and ethical principles, honesty, and
                    consitancy in actions, decisions, and behavioures, we have a
                    commitment to doing what is right, and aligning our actions
                    with the highest ethical values and standards.
                  </span>
                </span>
              </div>
              <div class="trinoray-frame66">
                <img src="/external/ellipse30130-yln9-200h.png" alt="Ellipse30130" class="trinoray-ellipse3" />
                <div class="trinoray-frame01">
                  <div class="trinoray-group04">
                    <img src="/external/vectori013-k2ck.svg" alt="VectorI013" class="trinoray-vector13" />
                    <img src="/external/vectori013-wut6.svg" alt="VectorI013" class="trinoray-vector14" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="trinoray-frame111">
                <span class="trinoray-text050"><span>reliability</span></span>
                <span class="trinoray-text052">
                  <span>
                    We consistently aim to perform as expected and promised,
                    meeting or exceeding the needs and expectations of our
                    customers and stakeholders and any other parties who use our
                    technology.
                  </span>
                </span>
              </div>
            </div>
            <div class="trinoray-frame67">
              <img src="/external/ellipse30137-hlqs-200h.png" alt="Ellipse30137" class="trinoray-ellipse31" />
              <div class="trinoray-frame02">
                <div class="trinoray-group05">
                  <img src="/external/vectori013-taqr.svg" alt="VectorI013" class="trinoray-vector15" />
                </div>
              </div>
            </div>
            <div>
              <div class="trinoray-frame112">
                <span class="trinoray-text054">
                  <span>ETHICAL INNOVATIVION</span>
                </span>
                <span class="trinoray-text056">
                  <span>
                    We prioritise our customers’ needs, being transparent,
                    promoting inclusivity, and considering all consequences
                    throughout the process. We pride ourselves on having the
                    opportunity to help people utilising cutting-edge artificial
                    intelligence despite of geographical location.
                  </span>
                </span>
              </div>
              <div class="trinoray-group1">
                <div class="trinoray-frame9"></div>
              </div>
              <div class="trinoray-frame68">
                <img src="/external/ellipse30146-7cwt-200h.png" alt="Ellipse30146" class="trinoray-ellipse32" />
                <div class="trinoray-frame03">
                  <div class="trinoray-group06">
                    <img src="/external/vectori014-mwjd.svg" alt="VectorI014" class="trinoray-vector16" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="trinoray-frame121">
              <div class="trinoray-frame113">
                <span class="trinoray-text058"><span>COLLABORATION</span></span>
                <span class="trinoray-text060">
                  <span>
                    We aim to collaborate in forming partnership that can ehance
                    expertise and innovation , as well as working together
                    internally with our customers and partners to meet theire
                    changing needs.
                  </span>
                </span>
                <div class="trinoray-frame671">
                  <img src="/external/ellipse30154-hj75h-200h.png" alt="Ellipse30154" class="trinoray-ellipse33" />
                  <div class="trinoray-frame04">
                    <div class="trinoray-group07">
                      <img src="/external/vectori015-kwdt.svg" alt="VectorI015" class="trinoray-vector17" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="trinoray-frame131">
              <div class="trinoray-frame114">
                <span class="trinoray-text062"><span>IMPACT</span></span>
                <span class="trinoray-text064">
                  <span>
                    We endeavour to consistently meet commitments and expectaions
                    by delivering a high - quality service , and being trust
                    worthy and dependable in our interactions with all
                    stakeholders. We aim to make a positive impact on the world,
                    helping those who work to help some of the most vulnerable
                    people in communities across the globe.
                  </span>
                </span>
              </div>
              <div class="trinoray-frame681">
                <img src="/external/ellipse30161-bkj-200h.png" alt="Ellipse30161" class="trinoray-ellipse34" />
                <div class="trinoray-frame05">
                  <div class="trinoray-group08">
                    <img src="/external/vectori016-gu3.svg" alt="VectorI016" class="trinoray-vector18" />
                    <img src="/external/vectori016-7de.svg" alt="VectorI016" class="trinoray-vector19" />
                  </div>
                </div>
              </div>
            </div>
            <div class="trinoray-frame141">
              <div class="trinoray-frame115">
                <span class="trinoray-text066">
                  <span>TRANSPARENCY &amp; ACCOUNTABILITY</span>
                </span>
                <span class="trinoray-text068">
                  <span>
                    We use open and honest communication whilst prioritising data
                    safety and security of our customers. We take responsibility
                    for our actions , decisions , and outcomes in all that we do.
                  </span>
                </span>
              </div>
              <div class="trinoray-frame69">
                <img src="/external/ellipse30168-p4hb-200h.png" alt="Ellipse30168" class="trinoray-ellipse35" />
                <div class="trinoray-frame06">
                  <div class="trinoray-group09">
                    <img src="/external/vectori016-ept.svg" alt="VectorI016" class="trinoray-vector20" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="trinoray-frame74">
        <div class="trinoray-frame181">
          <img src="/external/line60172-wzal.svg" alt="Line60172" class="trinoray-line61" />
          <span class="trinoray-text070"><span>Our Services</span></span>
        </div>
        <span class="trinoray-text072">
          <span>
            our solutions to our cusomer’s problems
            <span v-html="rawnovo"></span>
          </span>
        </span>
      </div>
      <div class="trinoray-partnership">
        <div class="trinoray-group7">
          <div class="trinoray-group6">
            <img src="/external/vector0179-zlcj.svg" alt="Vector0179" class="trinoray-vector21" />
            <img src="/external/vector0180-ij3.svg" alt="Vector0180" class="trinoray-vector22" />
            <img src="/external/vector0181-aybo.svg" alt="Vector0181" class="trinoray-vector23" />
            <img src="/external/vector0182-ckrm.svg" alt="Vector0182" class="trinoray-vector24" />
          </div>
        </div>
        <div class="trinoray-group9">
          <img src="/external/ellipse50184-eos.svg" alt="Ellipse50184" class="trinoray-ellipse5" />
          <div class="trinoray-group8">
            <img src="/external/image698shotsso10186-uhx7-500w.png" alt="IMAGE698shotsso10186"
              class="trinoray-image698shotsso1" />
            <img src="/external/image390shotsso10187-tt7k-1100w.png" alt="IMAGE390shotsso10187"
              class="trinoray-image390shotsso1" />
          </div>
        </div>
        <div class="trinoray-frame19">
          <div class="trinoray-frame161">
            <div class="trinoray-frame17">
              <img src="/external/line50191-l6oq.svg" alt="Line50191" class="trinoray-line51" />
              <span class="trinoray-text074"><span>Partnership</span></span>
            </div>
            <span class="trinoray-text076">
              <span>
                When you partner with us, you gain more than just a tailored
                solution —you gain a dedicated ally committed to enhancing your
                operational capabilities from a team who understands your needs.
                Our tailored solutions are designed to streamline your data
                processes, enabling you to make informed decisions quickly and
                effectively. We understand that every project, no matter its size,
                requires meticulous attention and expertise to achieve its goals
              </span>
            </span>
          </div>
          <div>
            <span class="trinoray-text078"><span>View All</span></span>
            <div class="trinoray-forword1">
              <div>
                <img src="/external/vectori019-727.svg" alt="VectorI019" class="trinoray-vector25" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="/external/vector0197-q7ov.svg" alt="Vector0197" class="trinoray-vector26" />
      <img src="/external/vector0198-e3v8.svg" alt="Vector0198" class="trinoray-vector27" />
      <div class="trinoray-req-demo">
        <div class="trinoray-frame20">
          <div class="trinoray-frame162">
            <div class="trinoray-frame171">
              <div class="trinoray-frame71">
                <div>
                  <img src="/external/line50205-9c8.svg" alt="Line50205" class="trinoray-line52" />
                  <span class="trinoray-text080"><span>Request Demo</span></span>
                </div>
                <div class="trinoray-frame21">
                  <span class="trinoray-text082">
                    <span>Experience Our Solutions Firsthand</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span class="trinoray-text084">
            <span>
              Want to experience simplified data management and revolutionize your
              operations? Discover how our AI can help you and your team
              streamline your information by trialling a free, tailor-made demo.
            </span>
          </span>
          <div class="trinoray-frame72">
            <span class="trinoray-text086"><span>Request Demo</span></span>
            <div class="trinoray-forword2">
              <div>
                <img src="/external/vectori021-mful.svg" alt="VectorI021" class="trinoray-vector28" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame60">
          <div class="trinoray-frame31">
            <div class="trinoray-frame30">
              <div class="trinoray-frame28">
                <div class="trinoray-frame23">
                  <span class="trinoray-text088"><span>First name</span></span>
                  <div class="trinoray-frame22">
                    <span class="trinoray-text090"><span>First name</span></span>
                  </div>
                </div>
                <div class="trinoray-frame24">
                  <span class="trinoray-text092"><span>Email address</span></span>
                  <div class="trinoray-frame221">
                    <span class="trinoray-text094">
                      <span>Email address</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="trinoray-frame29">
                <div class="trinoray-frame25">
                  <span class="trinoray-text096"><span>Company Name</span></span>
                  <div class="trinoray-frame222">
                    <span class="trinoray-text098">
                      <span>Company name</span>
                    </span>
                  </div>
                </div>
                <div class="trinoray-frame26">
                  <span class="trinoray-text100"><span>Position</span></span>
                  <div class="trinoray-frame223">
                    <span class="trinoray-text102"><span>Position</span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="trinoray-frame27">
              <span class="trinoray-text104"><span>Message</span></span>
              <div class="trinoray-frame224">
                <span class="trinoray-text106">
                  <span>e.g., specific areas of interest, questions</span>
                </span>
              </div>
            </div>
          </div>
          <div class="trinoray-frame172">
            <span class="trinoray-text108"><span>Contect US</span></span>
            <div class="trinoray-forword3">
              <div class="trinoray-group12">
                <img src="/external/vectori024-qken.svg" alt="VectorI024" class="trinoray-vector29" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="trinoray-cta">
        <div class="trinoray-frame47">
          <div class="trinoray-frame46">
            <img src="/external/line60244-ci0m.svg" alt="Line60244" class="trinoray-line62" />
            <span class="trinoray-text110">
              <span>Ready to Secure Your Data? Let&apos;s Get Started</span>
            </span>
          </div>
          <div class="trinoray-frame182">
            <span class="trinoray-text112">
              <span>
                Ready to get reliable solutions tailored to the humanitarian
                sector?
              </span>
            </span>
          </div>
        </div>
        <div class="trinoray-frame49">
          <div class="trinoray-frame173">
            <span class="trinoray-text114">
              <span>Enter your email address</span>
            </span>
          </div>
          <div class="trinoray-frame48">
            <span class="trinoray-text116"><span>Get Started</span></span>
            <div class="trinoray-forword4">
              <div class="trinoray-group13">
                <img src="/external/vectori025-slca.svg" alt="VectorI025" class="trinoray-vector30" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="trinoray-aboutus">
        <div class="trinoray-group11">
          <div class="trinoray-frame64">
            <div class="trinoray-frame163">
              <div class="trinoray-frame183">
                <img src="/external/line60259-lmfc.svg" alt="Line60259" class="trinoray-line63" />
                <span class="trinoray-text118"><span>About Us</span></span>
              </div>
              <span class="trinoray-text120">
                <span>Developed by humanitarian field experts</span>
              </span>
            </div>
            <span class="trinoray-text122">
              <span>
                We are committed to revolutionizing the approaches currently used
                to address the unique challenges faced by professionals in crucial
                sectors, including humanitarian and foreign aid. Our solutions are
                grounded in real-life experience, supported by a team actively
                working on the ground in conflict and disaster-affected areas
                across the globe, including Iraq, Libya, the Philippines, Yemen,
                and Ukraine.
              </span>
              <br />
              <span></span>
              <br />
              <span>
                With over 20 years of combined experience, we&apos;ve identified a
                significant gap in reliable, efficient, and effective systems. In
                fact, we have felt the frustration of not having reliable
                solutions that are not adequately tailored to the humanitarian
                sector. This insight drives our focus on precision and efficiency,
                providing tailored solutions, accessibility, and timeliness—vital
                components in crisis response scenarios.
              </span>
            </span>
            <div class="trinoray-frame73">
              <span class="trinoray-text128"><span>Read More</span></span>
              <div class="trinoray-forword5">
                <div class="trinoray-group14">
                  <img src="/external/vectori026-t8c.svg" alt="VectorI026" class="trinoray-vector31" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-group10">
          <img src="/external/ellipse70268-ima-400h.png" alt="Ellipse70268" class="trinoray-ellipse7" />
          <img src="/external/rectangle110269-huhq-600h.png" alt="Rectangle110269" class="trinoray-rectangle11" />
        </div>
      </div>

      <div>
        <div class="trinoray-frame85">
          <span class="trinoray-text130">
            <span>
              spend less time searching for the data you need and more time
              implementing solution
            </span>
          </span>
          <div class="trinoray-frame75">
            <img src="/external/ellipse80273-nkz-200h.png" alt="Ellipse80273" class="trinoray-ellipse8" />
            <div class="trinoray-frame07">
              <div class="trinoray-group15">
                <img src="/external/vectori027-dd34.svg" alt="VectorI027" class="trinoray-vector32" />
                <img src="/external/vectori027-efo.svg" alt="VectorI027" class="trinoray-vector33" />
                <img src="/external/vectori027-oflm.svg" alt="VectorI027" class="trinoray-vector34" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame88">
          <span class="trinoray-text132">
            <span>cost-effective data management solution</span>
          </span>
          <div class="trinoray-frame7501">
            <img src="/external/ellipse80278-xlnd-200h.png" alt="Ellipse80278" class="trinoray-ellipse801" />
            <div class="trinoray-frame08">
              <div class="trinoray-group16">
                <img src="/external/vectori027-j9ti.svg" alt="VectorI027" class="trinoray-vector35" />
                <img src="/external/vectori027-x5yl.svg" alt="VectorI027" class="trinoray-vector36" />
                <img src="/external/vectori027-5h1.svg" alt="VectorI027" class="trinoray-vector37" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame91">
          <span class="trinoray-text134">
            <span>increased collaboration with your team</span>
          </span>
          <div class="trinoray-frame7502">
            <img src="/external/ellipse80283-dh5-200h.png" alt="Ellipse80283" class="trinoray-ellipse802" />
            <div class="trinoray-frame09">
              <div class="trinoray-group17">
                <img src="/external/vectori028-190m.svg" alt="VectorI028" class="trinoray-vector38" />
                <img src="/external/vectori028-39h8.svg" alt="VectorI028" class="trinoray-vector39" />
                <img src="/external/vectori028-uzy7.svg" alt="VectorI028" class="trinoray-vector40" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame86">
          <span class="trinoray-text136">
            <span>real-time data sent direto you</span>
          </span>
          <div class="trinoray-frame7503">
            <img src="/external/ellipse80288-w9q1-200h.png" alt="Ellipse80288" class="trinoray-ellipse803" />
            <div class="trinoray-frame10">
              <div class="trinoray-group18">
                <img src="/external/vectori028-q1gi.svg" alt="VectorI028" class="trinoray-vector41" />
                <img src="/external/vectori028-xoem.svg" alt="VectorI028" class="trinoray-vector42" />
                <img src="/external/vectori028-nbcb.svg" alt="VectorI028" class="trinoray-vector43" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame89">
          <span class="trinoray-text138">
            <span>accurate and live data deliveres quickly</span>
          </span>
          <div class="trinoray-frame7504">
            <img src="/external/ellipse80293-kh9r-200h.png" alt="Ellipse80293" class="trinoray-ellipse804" />
            <div class="trinoray-frame11">
              <div class="trinoray-group19">
                <img src="/external/vectori029-dnw7.svg" alt="VectorI029" class="trinoray-vector44" />
                <img src="/external/vectori029-log.svg" alt="VectorI029" class="trinoray-vector45" />
                <img src="/external/vectori029-2a4.svg" alt="VectorI029" class="trinoray-vector46" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame92">
          <span class="trinoray-text140">
            <span>Optimised decision-maing</span>
          </span>
          <div class="trinoray-frame7505">
            <img src="/external/ellipse80298-q69k9-200h.png" alt="Ellipse80298" class="trinoray-ellipse805" />
            <div class="trinoray-frame12">
              <div class="trinoray-group20">
                <img src="/external/vectori029-i7p.svg" alt="VectorI029" class="trinoray-vector47" />
                <img src="/external/vectori029-vok.svg" alt="VectorI029" class="trinoray-vector48" />
                <img src="/external/vectori029-b7p8.svg" alt="VectorI029" class="trinoray-vector49" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame87">
          <span class="trinoray-text142">
            <span>view all your data sources in one place</span>
          </span>
          <div class="trinoray-frame7506">
            <img src="/external/ellipse80303-k4v4-200h.png" alt="Ellipse80303" class="trinoray-ellipse806" />
            <div class="trinoray-frame13">
              <div class="trinoray-group21">
                <img src="/external/vectori030-4cj.svg" alt="VectorI030" class="trinoray-vector50" />
                <img src="/external/vectori030-y0xf.svg" alt="VectorI030" class="trinoray-vector51" />
                <img src="/external/vectori030-7v2.svg" alt="VectorI030" class="trinoray-vector52" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame90">
          <span class="trinoray-text144">
            <span>Flexible and scalable depending in immient needs</span>
          </span>
          <div class="trinoray-frame7507">
            <img src="/external/ellipse80308-6fri-200h.png" alt="Ellipse80308" class="trinoray-ellipse807" />
            <div class="trinoray-frame14">
              <div class="trinoray-group22">
                <img src="/external/vectori030-2n5.svg" alt="VectorI030" class="trinoray-vector53" />
                <img src="/external/vectori030-5l8.svg" alt="VectorI030" class="trinoray-vector54" />
                <img src="/external/vectori030-pzx.svg" alt="VectorI030" class="trinoray-vector55" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame93">
          <span class="trinoray-text146">
            <span>Streamilined data managment</span>
          </span>
          <div class="trinoray-frame7508">
            <img src="/external/ellipse80313-mk8e-200h.png" alt="Ellipse80313" class="trinoray-ellipse808" />
            <div class="trinoray-frame15">
              <div class="trinoray-group23">
                <img src="/external/vectori031-e5yo.svg" alt="VectorI031" class="trinoray-vector56" />
                <img src="/external/vectori031-7fe.svg" alt="VectorI031" class="trinoray-vector57" />
                <img src="/external/vectori031-zzxq.svg" alt="VectorI031" class="trinoray-vector58" />
              </div>
            </div>
          </div>
        </div>
        <div class="trinoray-frame80">
          <span class="trinoray-text148">
            <span>increased collaboration with your team</span>
          </span>
          <div class="trinoray-frame7509">
            <img src="/external/ellipse80318-6ajr-200h.png" alt="Ellipse80318" class="trinoray-ellipse809" />
            <div class="trinoray-frame16">
              <div class="trinoray-group24"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trinoray',
  data() {
    return {
      rawcr12: ' ',
      rawib3t: ' ',
      rawnovo: ' ',
    }
  },
  metaInfo: {
    title: 'exported project',
  },
}
</script>

<style scoped>
.trinoray-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.trinoray-trinoray {
  width: 100%;
  height: 6300px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
}

.trinoray-footer {
  top: 5767px;
  left: 0px;
  width: 1920px;
  height: 533px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(0, 0, 0, 1);
}

.trinoray-frame59 {
  gap: 59px;
  top: 120px;
  left: 125px;
  width: 1440px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-default-blac);
}

.trinoray-frame58 {
  gap: 244px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}

.trinoray-frame50 {
  gap: 22px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-frame4 {
  gap: 115px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.trinoray-frame70 {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-vector1 {
  width: 27px;
  height: 25px;
}

.trinoray-image202405281832021 {
  width: 114px;
  height: 35px;
}

.trinoray-text {
  color: rgba(255, 255, 255, 1);
  width: 374px;
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame57 {
  gap: 100px;
  display: flex;
  align-items: flex-start;
}

.trinoray-frame52 {
  gap: 25px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text002 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame51 {
  gap: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text004 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text006 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text008 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text010 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text012 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame53 {
  gap: 25px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text014 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame511 {
  gap: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text016 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text018 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame56 {
  gap: 25px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame54 {
  gap: 25px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text020 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame55 {
  gap: 25px;
  display: flex;
  align-items: flex-start;
}

.trinoray-frame {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}

.trinoray-insta {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group01 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector01 {
  top: 4.440890312194824px;
  left: 6.834562301635742px;
  width: 13px;
  height: 13px;
  position: absolute;
}

.trinoray-vector02 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}

.trinoray-twitter {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group02 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector03 {
  top: 4.721565246582031px;
  left: 5.784254550933838px;
  width: 12px;
  height: 15px;
  position: absolute;
}

.trinoray-vector04 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}

.trinoray-text022 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-ellipse4 {
  top: 784px;
  left: 1248px;
  width: 906px;
  height: 906px;
  position: absolute;
}

.trinoray-ellipse6 {
  top: 4492px;
  left: 1202px;
  width: 906px;
  height: 906px;
  position: absolute;
}

.trinoray-rectangle5 {
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1128px;
  position: absolute;
}

.trinoray-n-avbar {
  gap: 272px;
  top: 26px;
  left: 125px;
  width: 1440px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}

.trinoray-frame3 {
  gap: 115px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.trinoray-frame701 {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-vector11 {
  width: 27px;
  height: 25px;
}

.trinoray-image2024052818320211 {
  width: 114px;
  height: 35px;
}

.trinoray-frame2 {
  gap: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-text024 {
  width: 76px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-line5 {
  width: 15px;
  height: 1px;
}

.trinoray-frame61 {
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-text026 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-line2 {
  width: 15px;
  height: 1px;
}

.trinoray-text028 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-line4 {
  width: 15px;
  height: 1px;
}

.trinoray-text030 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame1 {
  gap: 10px;
  width: 165px;
  height: 53px;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text032 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-vector05 {
  top: 741px;
  left: 375px;
  width: 33px;
  height: 33px;
  position: absolute;
}

.trinoray-vector06 {
  top: 779.3072509765625px;
  left: 1655.7064208984375px;
  width: 33px;
  height: 33px;
  position: absolute;
}

.trinoray-vector07 {
  top: 245.03109741210938px;
  left: 414px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.trinoray-group3 {
  top: 451px;
  left: 1376.78857421875px;
  width: 409.47686767578125px;
  height: 188.52684020996094px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector08 {
  top: 126.59049588529024px;
  left: -116.48597676840018px;
  width: 86px;
  height: 17px;
  position: absolute;
}

.trinoray-vector09 {
  top: 163.7375248039284px;
  left: -2.402804979411485px;
  width: 169px;
  height: 67px;
  position: absolute;
}

.trinoray-vector10 {
  top: 11.128910825709852px;
  left: 10.231207298162872px;
  width: 381px;
  height: 160px;
  position: absolute;
}

.trinoray-vector11 {
  top: 26.760197952319913px;
  left: -29.108167705960568px;
  width: 409px;
  height: 109px;
  position: absolute;
}

.trinoray-herosection {
  top: 203px;
  left: 20px;
  width: 1649px;
  height: 925px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-frame7 {
  gap: 38px;
  top: 0px;
  left: 303.5px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}

.trinoray-frame6 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.trinoray-frame5 {
  gap: 17px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.trinoray-text034 {
  color: rgba(125, 226, 251, 1);
  width: 1042px;
  height: auto;
  font-size: 52px;
  font-style: italic;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text035 {
  color: var(--dl-color-default-blue);
}

.trinoray-text036 {
  color: rgba(241, 109, 135, 1);
  font-weight: 700;
}

.trinoray-text038 {
  color: rgba(198, 198, 198, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame41 {
  gap: 10px;
  width: 165px;
  height: 53px;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text040 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-forword {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group03 {
  top: 4.499702453613281px;
  left: 2.9999959468841553px;
  width: 18.000003814697266px;
  height: 15.000299453735352px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector12 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 15px;
  position: absolute;
}

.trinoray-group2 {
  top: 217px;
  left: 0px;
  width: 1649px;
  height: 708px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-image205shotsso1 {
  top: 0px;
  left: 0px;
  width: 1649px;
  height: 708px;
  position: absolute;
}

.trinoray-ourcompanyvalues {
  gap: 120px;
  top: 2005px;
  left: 125px;
  width: 1440px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}

.trinoray-frame8 {
  gap: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame18 {
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-line6 {
  width: 55px;
  height: 1px;
}

.trinoray-text042 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 45px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text044 {
  color: rgba(129, 129, 129, 1);
  width: 1092px;
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame73 {
  gap: 72px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.trinoray-frame15 {
  gap: 30px;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.trinoray-frame12 {
  gap: 19px;
  height: 300px;
  display: flex;
  padding: 25px;
  position: relative;
  align-self: stretch;
  box-shadow: 0px 6px 54px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 9px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.trinoray-frame11 {
  gap: 25px;
  display: flex;
  padding: 30px 0 0;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-text046 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text048 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame66 {
  gap: 10px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: flex-start;
}

.trinoray-ellipse3 {
  width: 77px;
  height: 77px;
}

.trinoray-frame01 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group04 {
  top: 1.6500000953674316px;
  left: 1.65274977684021px;
  width: 40.696224212646484px;
  height: 40.700340270996094px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector13 {
  top: 0px;
  left: 0px;
  width: 41px;
  height: 41px;
  position: absolute;
}

.trinoray-vector14 {
  top: 10.38172721862793px;
  left: 15.139835357666016px;
  width: 10px;
  height: 7px;
  position: absolute;
}

.trinoray-frame13 {
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.trinoray-frame111 {
  gap: 25px;
  display: flex;
  padding: 30px 0 0;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-text050 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text052 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame67 {
  gap: 10px;
  display: flex;
  z-index: 2;
  position: relative;
  align-items: flex-start;
}

.trinoray-ellipse31 {
  width: 77px;
  height: 77px;
}

.trinoray-frame02 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group05 {
  top: 0.687030553817749px;
  left: 0.6874371767044067px;
  width: 42.62550735473633px;
  height: 42.62598419189453px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector15 {
  top: 0px;
  left: 0px;
  width: 43px;
  height: 43px;
  position: absolute;
}

.trinoray-frame14 {
  gap: 19px;
  width: 460px;
  display: flex;
  padding: 25px;
  z-index: 3;
  position: absolute;
  box-shadow: 0px 6px 54px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 9px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.trinoray-frame112 {
  gap: 25px;
  display: flex;
  padding: 30px 0 0;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-text054 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text056 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-group1 {
  top: 258px;
  left: 35px;
  width: 77px;
  height: 77px;
  display: flex;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-frame9 {
  gap: 10px;
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
}

.trinoray-frame68 {
  gap: 10px;
  display: flex;
  z-index: 2;
  position: relative;
  align-items: flex-start;
}

.trinoray-ellipse32 {
  width: 77px;
  height: 77px;
}

.trinoray-frame03 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group06 {
  top: 1.288698434829712px;
  left: 4.478789806365967px;
  width: 35.04471206665039px;
  height: 41.4213752746582px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector16 {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 41px;
  position: absolute;
}

.trinoray-frame16 {
  gap: 30px;
  display: flex;
  align-items: flex-start;
}

.trinoray-frame121 {
  gap: 19px;
  height: 324px;
  display: flex;
  padding: 25px;
  align-self: stretch;
  box-shadow: 0px 6px 54px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 9px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.trinoray-frame113 {
  gap: 25px;
  display: flex;
  padding: 30px 0 0;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-text058 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text060 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  z-index: 1;
  font-size: 18px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame671 {
  gap: 10px;
  display: flex;
  z-index: 2;
  position: relative;
  align-items: flex-start;
}

.trinoray-ellipse33 {
  width: 77px;
  height: 77px;
}

.trinoray-frame04 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group07 {
  top: 0.3436640501022339px;
  left: 0.3437349796295166px;
  width: 43.3126106262207px;
  height: 43.31267547607422px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector17 {
  top: 0px;
  left: 0px;
  width: 43px;
  height: 43px;
  position: absolute;
}

.trinoray-frame131 {
  gap: 19px;
  width: 460px;
  display: flex;
  padding: 25px;
  position: relative;
  box-shadow: 0px 6px 54px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 9px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.trinoray-frame114 {
  gap: 25px;
  display: flex;
  padding: 30px 0 0;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-text062 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text064 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame681 {
  gap: 10px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: flex-start;
}

.trinoray-ellipse34 {
  width: 77px;
  height: 77px;
}

.trinoray-frame05 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group08 {
  top: 2.7929084300994873px;
  left: 3.1543314456939697px;
  width: 37.685935974121094px;
  height: 38.456260681152344px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector18 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
}

.trinoray-vector19 {
  top: 21.93646812438965px;
  left: 23.77410316467285px;
  width: 11px;
  height: 14px;
  position: absolute;
}

.trinoray-frame141 {
  gap: 19px;
  height: 324px;
  display: flex;
  padding: 25px;
  position: relative;
  align-self: stretch;
  box-shadow: 0px 6px 54px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 9px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.trinoray-frame115 {
  gap: 25px;
  display: flex;
  padding: 30px 0 0;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-text066 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text068 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame69 {
  gap: 10px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: flex-start;
}

.trinoray-ellipse35 {
  width: 77px;
  height: 77px;
}

.trinoray-frame06 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group09 {
  top: 1.1171875px;
  left: 1.1173592805862427px;
  width: 41.76536178588867px;
  height: 41.765625px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector20 {
  top: 0px;
  left: 0px;
  width: 42px;
  height: 42px;
  position: absolute;
}

.trinoray-frame74 {
  gap: 15px;
  top: 1248px;
  left: 768px;
  width: 384px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame181 {
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-line61 {
  width: 55px;
  height: 1px;
}

.trinoray-text070 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 45px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text072 {
  color: rgba(129, 129, 129, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-partnership {
  top: 3846px;
  left: 0px;
  width: 2186px;
  height: 806px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-group7 {
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 806px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(19, 46, 88, 1);
}

.trinoray-group6 {
  top: 51px;
  left: 141px;
  width: 1327.64990234375px;
  height: 420.3999938964844px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector21 {
  top: 272px;
  left: 1296.7999267578125px;
  width: 33px;
  height: 33px;
  position: absolute;
}

.trinoray-vector22 {
  top: 0px;
  left: 1153.2337646484375px;
  width: 18px;
  height: 18px;
  position: absolute;
}

.trinoray-vector23 {
  top: 264.0400390625px;
  left: 777px;
  width: 18px;
  height: 18px;
  position: absolute;
}

.trinoray-vector24 {
  top: 387px;
  left: 0px;
  width: 33px;
  height: 33px;
  position: absolute;
}

.trinoray-group9 {
  top: 7.649658203125px;
  left: 1048px;
  width: 1138px;
  height: 798.350341796875px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-ellipse5 {
  top: 317.34619140625px;
  left: 218.6785888671875px;
  width: 697px;
  height: 520px;
  position: absolute;
}

.trinoray-group8 {
  top: 98.35033416748047px;
  left: 0px;
  width: 1138px;
  height: 700px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-image698shotsso1 {
  top: 0px;
  left: 0px;
  width: 417px;
  height: 700px;
  position: absolute;
}

.trinoray-image390shotsso1 {
  top: 115px;
  left: 117px;
  width: 1021px;
  height: 585px;
  position: absolute;
}

.trinoray-frame19 {
  gap: 25px;
  top: 208px;
  left: 241px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-frame161 {
  gap: 25px;
  width: 705px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame17 {
  gap: 25px;
  display: flex;
  align-items: center;
}

.trinoray-line51 {
  width: 55px;
  height: 1px;
}

.trinoray-text074 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 45px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text076 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame71 {
  gap: 10px;
  width: 400px;
  height: 53px;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text078 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-forword1 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group10 {
  top: 4.499702453613281px;
  left: 2.9999959468841553px;
  width: 18.000003814697266px;
  height: 15.000299453735352px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector25 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 15px;
  position: absolute;
}

.trinoray-vector26 {
  top: 354px;
  left: 1528.2337646484375px;
  width: 18px;
  height: 18px;
  position: absolute;
}

.trinoray-vector27 {
  top: 484.82379150390625px;
  left: 516px;
  width: 18px;
  height: 18px;
  position: absolute;
}

.trinoray-req-demo {
  top: 4772px;
  left: 125px;
  width: 1440px;
  height: 689px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-frame20 {
  gap: 25px;
  top: 0px;
  left: 735px;
  width: 705px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.trinoray-frame162 {
  gap: 15px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame171 {
  gap: 25px;
  width: 454px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.trinoray-frame71 {
  gap: 15px;
  height: 115px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.trinoray-frame72 {
  gap: 10px;
  display: flex;
  align-items: center;
}

.trinoray-line52 {
  width: 55px;
  height: 1px;
}

.trinoray-text080 {
  color: var(--dl-color-default-primary);
  width: 705px;
  height: auto;
  font-size: 45px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame21 {
  gap: 25px;
  height: 43px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
}

.trinoray-text082 {
  color: var(--dl-color-default-primary);
  height: auto;
  flex-grow: 1;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text084 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame72 {
  gap: 10px;
  height: 53px;
  display: flex;
  padding: 10px 17px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text086 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-forword2 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group11 {
  top: 4.499702453613281px;
  left: 2.9999959468841553px;
  width: 18.000003814697266px;
  height: 15.000299453735352px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector28 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 15px;
  position: absolute;
}

.trinoray-frame60 {
  gap: 33px;
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-frame31 {
  gap: 18px;
  width: 643px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.trinoray-frame30 {
  gap: 18px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-frame28 {
  gap: 18px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-frame23 {
  gap: 15px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text088 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-style: Semibold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame22 {
  gap: 10px;
  display: flex;
  padding: 11px 30px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(208, 208, 208, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
}

.trinoray-text090 {
  color: rgba(208, 208, 208, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame24 {
  gap: 15px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text092 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-style: Semibold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame221 {
  gap: 10px;
  display: flex;
  padding: 11px 30px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(208, 208, 208, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
}

.trinoray-text094 {
  color: rgba(208, 208, 208, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame29 {
  gap: 18px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-frame25 {
  gap: 15px;
  width: 312.5px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.trinoray-text096 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-style: Semibold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame222 {
  gap: 10px;
  display: flex;
  padding: 11px 30px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(208, 208, 208, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
}

.trinoray-text098 {
  color: rgba(208, 208, 208, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame26 {
  gap: 15px;
  width: 312.5px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.trinoray-text100 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-style: Semibold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame223 {
  gap: 10px;
  display: flex;
  padding: 11px 30px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(208, 208, 208, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
}

.trinoray-text102 {
  color: rgba(208, 208, 208, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame27 {
  gap: 15px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-text104 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-style: Semibold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame224 {
  gap: 10px;
  display: flex;
  padding: 17px 30px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(208, 208, 208, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
}

.trinoray-text106 {
  color: rgba(208, 208, 208, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame172 {
  gap: 10px;
  display: flex;
  padding: 10px 17px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text108 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-forword3 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group12 {
  top: 4.499702453613281px;
  left: 2.9999959468841553px;
  width: 18.000003814697266px;
  height: 15.000299453735352px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector29 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 15px;
  position: absolute;
}

.trinoray-cta {
  gap: 25px;
  top: 5581px;
  left: 125px;
  width: 1440px;
  display: flex;
  padding: 45px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: space-between;
  background-color: var(--dl-color-default-primary);
}

.trinoray-frame47 {
  gap: 25px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame46 {
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-line62 {
  width: 55px;
  height: 1px;
}

.trinoray-text110 {
  color: rgba(198, 198, 198, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame182 {
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-text112 {
  color: rgba(255, 255, 255, 1);
  width: 640px;
  height: auto;
  font-size: 36px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame49 {
  gap: 21px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame173 {
  gap: 10px;
  width: 503px;
  height: 62px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(40, 77, 133, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
}

.trinoray-text114 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame48 {
  gap: 10px;
  height: 53px;
  display: flex;
  padding: 10px 17px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text116 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-forword4 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group13 {
  top: 4.499702453613281px;
  left: 2.9999959468841553px;
  width: 18.000003814697266px;
  height: 15.000299453735352px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector30 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 15px;
  position: absolute;
}

.trinoray-aboutus {
  top: 3089px;
  left: -8px;
  width: 1688px;
  height: 637px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-group11 {
  top: 0px;
  left: 983px;
  width: 705px;
  height: 637px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-frame64 {
  gap: 25px;
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}

.trinoray-frame163 {
  gap: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.trinoray-frame183 {
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trinoray-line63 {
  width: 55px;
  height: 1px;
}

.trinoray-text118 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 45px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text120 {
  color: var(--dl-color-default-primary);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-text122 {
  color: rgba(0, 0, 0, 1);
  width: 705px;
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame73 {
  gap: 10px;
  width: 165px;
  height: 53px;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text128 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-forword5 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group14 {
  top: 4.499702453613281px;
  left: 2.9999959468841553px;
  width: 18.000003814697266px;
  height: 15.000299453735352px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector31 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 15px;
  position: absolute;
}

.trinoray-group10 {
  top: 26px;
  left: 946px;
  width: 946px;
  height: 586px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(238, 251, 254, 1);
}

.trinoray-ellipse7 {
  top: 106px;
  left: -356px;
  width: 394px;
  height: 394px;
  position: absolute;
}

.trinoray-rectangle11 {
  top: 32px;
  left: -45px;
  width: 664px;
  height: 516px;
  position: absolute;
  border-radius: 9px;
}

.trinoray-frame85 {
  gap: 21px;
  top: 1765px;
  left: 1175px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text130 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame75 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse8 {
  width: 120px;
  height: 120px;
}

.trinoray-frame07 {
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.trinoray-group15 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector32 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector33 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector34 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame88 {
  gap: 21px;
  top: 1595px;
  left: 1175px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text132 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7501 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse801 {
  width: 120px;
  height: 120px;
}

.trinoray-frame08 {
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.trinoray-group16 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector35 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector36 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector37 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame91 {
  gap: 21px;
  top: 1425px;
  left: 1175px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text134 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7502 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse802 {
  width: 120px;
  height: 120px;
}

.trinoray-frame09 {
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.trinoray-group17 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector38 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector39 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector40 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame86 {
  gap: 21px;
  top: 1765px;
  left: 655px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text136 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7503 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse803 {
  width: 120px;
  height: 120px;
}

.trinoray-frame10 {
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.trinoray-group18 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector41 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector42 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector43 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame89 {
  gap: 21px;
  top: 1595px;
  left: 655px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text138 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7504 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse804 {
  width: 120px;
  height: 120px;
}

.trinoray-frame11 {
  top: 35px;
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.trinoray-group19 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector44 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector45 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector46 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame92 {
  gap: 21px;
  top: 1425px;
  left: 655px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text140 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7505 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse805 {
  width: 120px;
  height: 120px;
}

.trinoray-frame12 {
  top: 35px;
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.trinoray-group20 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector47 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector48 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector49 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame87 {
  gap: 21px;
  top: 1765px;
  left: 125px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text142 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7506 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse806 {
  width: 120px;
  height: 120px;
}

.trinoray-frame13 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group21 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector50 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector51 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector52 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame90 {
  gap: 21px;
  top: 1595px;
  left: 125px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text144 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7507 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse807 {
  width: 120px;
  height: 120px;
}

.trinoray-frame14 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group22 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector53 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector54 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector55 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame93 {
  gap: 21px;
  top: 1425px;
  left: 125px;
  width: 406px;
  height: 130px;
  display: flex;
  padding: 0 50px 0 80px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text146 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7508 {
  gap: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.trinoray-ellipse808 {
  width: 120px;
  height: 120px;
}

.trinoray-frame15 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group23 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

.trinoray-vector56 {
  top: 2.8386850357055664px;
  left: 8.692130088806152px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.trinoray-vector57 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.trinoray-vector58 {
  top: 9.935483932495117px;
  left: 12.774194717407227px;
  width: 18px;
  height: 1px;
  position: absolute;
}

.trinoray-frame80 {
  gap: 21px;
  top: 1803px;
  left: -566px;
  width: 498px;
  height: 77px;
  display: flex;
  padding: 0 50px 0 60px;
  position: absolute;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.18000000715255737);
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  justify-content: center;
  background-color: var(--dl-color-default-secondry);
}

.trinoray-text148 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 22px;
  font-style: Bold;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 700;
  line-height: 33px;
  font-stretch: normal;
  text-decoration: none;
}

.trinoray-frame7509 {
  gap: 10px;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: flex-start;
}

.trinoray-ellipse809 {
  width: 77px;
  height: 77px;
}

.trinoray-frame16 {
  width: 44px;
  height: 44px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}

.trinoray-group24 {
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44.00004959106445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}

/* Grid container styling */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Creates three equal-width columns */
  gap: 10px;
  /* Space between grid items */
  height: 100vh;
  /* Full viewport height */
  padding: 10px;
}

/* Grid item styling */
.grid-item {
  background-color: lightblue;
  border: 1px solid #000;
  display: flex;
  /* Use flexbox inside the grid item */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

/* Flex container styling (optional, for additional flex items inside grid items) */
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Flex item styling */
.flex-item {
  padding: 20px;
  background-color: white;
  border: 1px solid #000;
  text-align: center;
}
</style>
